<template>
  <v-container fluid class="page-wrapper fill-height py-0">
    <v-row class="fill-height">
      <v-col cols="12" md="10" offset-md="1" class="content-wrapper py-0">
        <v-row justify="center" justify-md="start" class="background-sochi">
          <v-col class="py-0">
            <v-row class="d-flex justify-space-between align-center">
              <div class="header__logo-icon">
                <svg width="55" height="25" viewBox="0 0 74 32">
                  <path
                    fill-rule="evenodd"
                    d="M51.6645 4.03989H61.9882C62.6713 4.03989 63.7072 4.03989 64.3926 4.71793C65.0782 5.39133 65.0782 6.40251 65.0782 7.0809V17.1967C65.0782 17.8675 65.0782 18.8845 64.3926 19.5553C63.7072 20.2303 62.6713 20.2303 61.9882 20.2303H57.0013C56.1409 20.2303 55.0213 20.2303 54.5925 19.301C54.162 18.376 54.7639 17.5322 55.2807 16.8572L61.7308 8.82759H51.2991L47.5448 13.4891L47.5373 13.499C46.8514 14.3981 46.2564 15.1781 46.2564 16.184C46.2564 17.1967 46.8081 17.927 47.5448 18.8845L48.5798 20.2303C49.6039 21.5785 50.6391 22.9294 52.0134 23.6035C53.392 24.2759 55.0213 24.2759 57.2587 24.2759H61.7308C64.3066 24.2759 68.1794 24.2759 70.7549 21.7498C73.3334 19.2184 73.3334 15.5106 73.3334 14.1582V10.1126C73.3334 8.76352 73.3334 5.05427 70.7549 2.52749C68.1794 0 64.3066 0 61.7308 0H54.5925C53.9071 0 53.0459 0 52.3612 0.671624C51.6645 1.34681 51.6645 2.18723 51.6645 2.86384V4.03989ZM39.3862 8.8276H49.641L37.3343 24.2759H27.0769L39.3862 8.8276ZM0.67091 9.4653C0 10.1105 0 10.9153 0 11.5564V12.6842H4.02865V32H12.0847V12.6842H19.9666C20.8106 12.6842 21.8996 12.6842 22.3224 13.5696C22.7409 14.4591 22.1542 15.2648 21.6481 15.9057L15.1022 24.2774H25.174L29.2012 19.1263C29.8787 18.2626 30.4615 17.5153 30.4615 16.5485C30.4615 15.5881 29.9184 14.8934 29.2085 13.9854L29.2012 13.9761L28.1989 12.6842L28.1697 12.6471C27.1675 11.3742 26.1676 10.1043 24.837 9.4653C23.4967 8.8276 21.8996 8.8276 19.7194 8.8276H2.85358C2.17913 8.8276 1.34359 8.8276 0.67091 9.4653Z"
                  />
                </svg>
              </div>
              <v-btn :to="{ name: 'project-statistic-no-nav', params: { projectId } }" class="white--text text-none mr-3" x-small text>
                Перейти на старую версию
              </v-btn>
            </v-row>
            <v-row>
              <v-col md="4" offset-md="1" class="pa-5 px-md-0 py-md-6">
                <div class="d-flex display-1 white--text">
                  <span class="title-wrapper px-1 px-md-2 font-weight-light">ТУРИСТИЧЕСКИЙ</span>
                </div>
                <div class="d-flex display-1 white--text">
                  <span class="title-wrapper px-1 px-md-2 font-weight-light">ПОЕЗД «СОЧИ»</span>
                </div>
                <div class="d-flex title white--text mb-5 mb-md-8">
                  <span class="highlight px-1 px-md-2 font-weight-light">CТАТИСТИКА ПРОДАЖ</span>
                </div>
                <date-widget :min-date="minSelectableDate" @change="onDateRangeChange" />
                <v-btn-toggle mandatory v-model="locationSelect" class="d-flex transparent mt-1" dark multiple>
                  <v-btn class="flex-grow-1 caption text-none" v-for="location in locations" :key="location" outlined>
                    {{ location }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-progress-linear color="#e21a1a" :active="isLoading" :indeterminate="true" />
        </v-row>
        <v-row class="mt-2 mt-md-4 d-flex flex-wrap-reverse">
          <v-col cols="12" md="5" offset-md="1" class="px-md-0">
            <v-expansion-panels multiple class="mb-4">
              <v-expansion-panel v-for="period in periods" :key="period">
                <v-expansion-panel-header :set="(total = getPeriodTotal(period))" class="justify-space-between">
                  {{ formatPeriodText(period) }}
                  <div class="d-flex justify-end caption">
                    <template v-if="total.collect !== 0">
                      <span :class="total.collect > 0 ? 'green--text' : 'error--text'" class="text--darken-2">
                        {{ formatPrice(total.collect, true) }} &#x20bd;</span
                      >
                      <span class="mx-1">/</span>
                    </template>
                    <span :class="total.collect < 0 ? 'error--text' : ''">{{ formatPrice(total.money, true) }} &#x20bd;</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content :set="(itemSet = getPeriodItems(period))">
                  <template v-for="(item, index) in itemSet.income">
                    <v-list-item :key="`${period}-${index}-income`" class="px-0" dense>
                      <v-list-item-content>
                        <v-list-item-title :set="(collect = item.periods[period].collect)" class="d-flex justify-space-between align-end caption">
                          <span v-text="item.title" class="text-wrap mr-4" />
                          <span v-if="collect > 0" class="green--text text--darken-2"> {{ formatPrice(collect, true) }} &#x20bd; </span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="d-flex align-center caption">
                          <v-chip label outlined x-small color="grey">
                            {{ isService(item.typeCode) ? 'У' : isFood(item.typeCode) ? 'П' : 'Т' }}
                          </v-chip>
                          <span class="ml-1"> {{ item.periods[period].count }} шт. x {{ formatPrice(item.price) }} &#x20bd; </span>
                          <span class="ml-auto"> {{ formatPrice(item.periods[period].money, true) }} &#x20bd; </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="`${period}-${index}-income-divider`" v-if="itemSet.refund.length > 0 || index < itemSet.income.length - 1" />
                  </template>
                  <template v-for="(item, index) in itemSet.refund">
                    <v-list-item :key="`${period}-${index}-refund`" class="px-0" dense>
                      <v-list-item-content>
                        <v-list-item-title :set="(collect = item.periods[period].collect)" class="d-flex justify-space-between align-end caption">
                          <span v-text="item.title" class="text-wrap mr-4" />
                          <span v-if="collect > 0" class="error--text text--darken-2"> {{ formatPrice(collect, true) }} &#x20bd; </span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="d-flex align-center caption">
                          <v-chip label outlined x-small color="error"> Возврат </v-chip>
                          <v-chip label outlined x-small color="grey" class="ml-1">
                            {{ isService(item.typeCode) ? 'У' : isFood(item.typeCode) ? 'П' : 'Т' }}
                          </v-chip>
                          <span class="ml-1"> {{ item.periods[period].count }} шт. x {{ formatPrice(item.price) }} &#x20bd; </span>
                          <span class="ml-auto error--text"> {{ formatPrice(item.periods[period].money, true) }} &#x20bd; </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="`${period}-${index}-refund-divider`" v-if="index < itemSet.refund.length - 1" />
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" md="4" offset-md="1">
            <v-divider />
            <div class="d-flex py-3 py-md-12 align-center justify-space-between">
              <v-chip-group v-model="saleGroupSelect" :set="(dataset = donutChart.data.datasets[0])" column multiple>
                <template v-for="(tag, index) in donutChart.data.labels">
                  <v-chip :key="tag" v-show="dataset.data[index].toString().length > 0" label small outlined>
                    <v-icon v-if="saleGroupSelect.includes(index)" :color="dataset.backgroundColor[index]" x-small left> mdi-square </v-icon>
                    {{ tag }}
                  </v-chip>
                </template>
              </v-chip-group>
              <donut-chart :width="188" :height="188" :chart-data="donutChart.data" :options="donutChart.options" />
            </div>
            <bar-chart
              v-if="saleGroupSelect.length > 0 && periods.length > 1"
              class="pb-3 pb-md-12"
              :chart-data="barChartData.data"
              :options="barChartData.options"
            />
            <line-chart
              v-if="saleGroupSelect.length > 0 && periods.length > 1"
              class="pb-3 pb-md-12"
              :chart-data="lineChartData.data"
              :options="lineChartData.options"
            />
            <v-divider />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateWidget from '../components/statistic/DateWidget'
import DonutChart from '../components/statistic/DonutChart'
import BarChart from '../components/statistic/BarChart'
import LineChart from '../components/statistic/LineChart'
import DonutChartWorker from '../helpers/statistic/DonutChartWorker'
import StackedBarChartWorker from '../helpers/statistic/StackedBarChartWorker'
import LineChartWorker from '../helpers/statistic/LineChartWorker'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { DateWidget, DonutChart, BarChart, LineChart },
  data() {
    return {
      dateRange: [null, null],
      minSelectableDate: '2021-06-01',
      splitByMonth: false,
      locationSelect: [0, 1, 2, 3],
      saleGroupSelect: [0, 1, 2, 3, 4, 5, 6],
      refreshInterval: null
    }
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    locations() {
      const groups = this.salesStatistics.groups
      return groups ? groups.map((it) => it.locationDescription) : []
    },
    periods() {
      const commonStats = this.salesStatistics.common
      return commonStats ? commonStats.periods : []
    },
    currentLocationGroups() {
      if (!this.salesStatistics.groups) return []
      return this.salesStatistics.groups.filter((_group, index) => this.locationSelect.includes(index))
    },
    currentStatsObject() {
      const resultObject = {
        services: { items: [] },
        goods: { items: [] },
        preOrders: { items: [] },
        refundedServices: { items: [] },
        refundedGoods: { items: [] },
        refundedPreOrders: { items: [] }
      }

      this.currentLocationGroups.forEach((group) => {
        this.processStatsObjectFieldObject(resultObject.services, group.services)
        this.processStatsObjectFieldObject(resultObject.goods, group.goods)
        this.processStatsObjectFieldObject(resultObject.preOrders, group.preOrders)
        this.processStatsObjectFieldObject(resultObject.refundedServices, group.servicesRefund)
        this.processStatsObjectFieldObject(resultObject.refundedGoods, group.goodsRefund)
        this.processStatsObjectFieldObject(resultObject.refundedPreOrders, group.preOrdersRefund)
      })
      return resultObject
    },
    currentItems() {
      return [...this.currentStatsObject.services.items, ...this.currentStatsObject.goods.items]
    },
    currentRefundedItems() {
      return [...this.currentStatsObject.refundedServices.items, ...this.currentStatsObject.refundedGoods.items]
    },
    donutChart() {
      return DonutChartWorker.buildChartData(this.currentStatsObject, this.saleGroupSelect, false)
    },
    barChartData() {
      return StackedBarChartWorker.buildChartData(this.currentStatsObject, this.periods, this.saleGroupSelect, false, this.splitByMonth)
    },
    lineChartData() {
      return LineChartWorker.buildChartData(this.currentStatsObject, this.periods, this.saleGroupSelect, false, this.splitByMonth)
    },
    ...mapGetters('project', ['salesStatistics', 'isLoading'])
  },
  mounted() {
    this.refreshInterval = setInterval(() => this.fetch(), 60000)
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  },
  methods: {
    fetch() {
      const queryParams = new URLSearchParams({ start: this.dateRange[0], end: this.dateRange[1] })
      if (this.splitByMonth) queryParams.append('split', 'month')
      this.fetchSalesStatistics({
        projectId: this.projectId,
        queryParams
      })
    },
    onDateRangeChange(range, splitByMonth) {
      this.splitByMonth = splitByMonth
      this.dateRange = range
      this.fetch()
    },
    processStatsObjectFieldObject(result, processingObject) {
      processingObject.items.forEach((item) => {
        const clonedItem = JSON.parse(JSON.stringify(item))
        let existingItem = result.items.find(
          (it) => it.title === clonedItem.title && it.typeCode === clonedItem.typeCode && it.price === clonedItem.price && it.article === clonedItem.article
        )
        if (!existingItem) {
          result.items.push(clonedItem)
        } else {
          existingItem.total.count += clonedItem.total.count
          existingItem.total.money += clonedItem.total.money
          existingItem.total.collect += clonedItem.total.collect
          this.periods.forEach((period) => {
            existingItem.periods[period].count += clonedItem.periods[period].count
            existingItem.periods[period].money += clonedItem.periods[period].money
            existingItem.periods[period].collect += clonedItem.periods[period].collect
          })
        }
      })
    },
    getPeriodItems(period) {
      return {
        income: this.currentItems.filter((item) => item.periods[period].count > 0),
        refund: this.currentRefundedItems.filter((item) => item.periods[period].count > 0)
      }
    },
    getPeriodTotal(period) {
      let money = 0
      let collect = 0
      this.currentLocationGroups.forEach((group) => {
        money += group.goods.total.periods[period].money + group.services.total.periods[period].money
        money -= group.goodsRefund.total.periods[period].money + group.servicesRefund.total.periods[period].money
        collect += group.goods.total.periods[period].collect + group.services.total.periods[period].collect
        collect -= group.goodsRefund.total.periods[period].collect + group.servicesRefund.total.periods[period].collect
      })
      return { money, collect }
    },
    isService(itemTypeCode) {
      return [1, 3, 4, 5, 7].includes(itemTypeCode)
    },
    isFood(itemTypeCode) {
      return 8 === itemTypeCode
    },
    formatPeriodText(period) {
      const date = this.$moment(period)
      let periodFormat = ''
      if (date.year() === this.$moment().year()) {
        periodFormat = this.splitByMonth ? 'MMMM' : 'D MMMM, ddd'
      } else {
        periodFormat = this.splitByMonth ? 'MMMM YYYY' : 'D MMMM YYYY'
      }
      const result = date.format(periodFormat)
      return periodFormat.startsWith('M') ? this.capitalizeFirstLetter(result) : result
    },
    capitalizeFirstLetter: (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    formatPrice(price, divideBySpaces) {
      const trailing = price.toString().split('.')[1]
      if (trailing && trailing.length > 0) price = Number(price).toFixed(2)

      if (divideBySpaces) {
        const parts = price.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        return parts.join('.')
      }
      return price
    },
    ...mapActions('project', ['fetchSalesStatistics'])
  }
}
</script>
<style scoped>
.page-wrapper {
  background: #333333;
}
.header__logo-icon {
  display: flex;
  width: 105px;
  height: 56px;
  align-items: center;
  justify-content: center;
  background: #e21a1a;
  fill: white;
}
.title-wrapper {
  background-color: rgba(0, 0, 0, 0.6);
}
.highlight {
  background: #e21a1a;
}
.background-sochi {
  background: linear-gradient(to bottom, rgba(6, 49, 7, 0.473), rgba(8, 23, 63, 0.568)), url('../assets/sochitrain.jpg') no-repeat center center;
  background-size: cover;
}
.content-wrapper {
  background: #e2e4e7;
}
.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  background-color: rgba(0, 0, 0, 0.9) !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
}
.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn--active {
  background-color: #e21a1a !important;
}
.v-input--checkbox {
  background-color: rgba(0, 0, 0, 0.4);
}
.v-input--checkbox /deep/ label {
  color: white;
  font-size: 0.875rem;
}
</style>
